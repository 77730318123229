







































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({
  methods: {
    ...mapActions({ resetPassword: 'auth/resetPassword' }),
  },
})
export default class ResetPassword extends Vue {
  color = BASE_BLACK_COLOR;
  show = false;
  showAlert = false;
  password = '';
  repeatPassword = '';
  newPasswordRules: Array<Function> = [];
  repeatPasswordRules: Array<Function> = [];
  resetPassword!: (password) => Promise<void>;

  onRepeatPasswordFocus() {
    this.repeatPasswordRules = [];
  }
  onNewPasswordFocus() {
    this.newPasswordRules = [];
  }
  async submit() {
    this.newPasswordRules = [(v) => !!v || 'New password is required'];
    this.repeatPasswordRules = [(v) => !!v || 'Repeat password is required'];

    if (
      (this.$refs.form as any).validate() &&
      this.password === this.repeatPassword
    ) {
      try {
        /* await this.resetPassword(this.password);
        return this.$router.push('/login');*/
      } catch (e) {
        console.log(e);
      }
    }
    if (this.password !== this.repeatPassword) {
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
    }
  }
}
